<template>
  <div class="edrea_tm_section" id="portfolio">
    <div class="section_inner">
      <div class="edrea_tm_portfolio swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Creative <span class="coloring">Portfolio</span></h3>
        </div>
        <div class="portfolio_list gallery_zoom">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/portfolio/eclipsefy_p.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/eclipsefy_project.jpg"></div>
                </div>
                <div class="details">
                  <h3>Eclipsefy</h3>
                  <span>Detalhes</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/eclipsefy_bg.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/eclipsefy_bg.jpg"
                        style="background-image: url('img/portfolio/eclipsefy_bg.jpg')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Eclipefy</h3>
                      <span>Logo & Website</span>
                    </div>
                    <div class="text">
                      <p></p>
                      <p>
                        Embarcamos em uma jornada com a Eclipsefy, uma agência de marketing inovadora, para redefinir sua presença online e conduzi-la ao auge da excelência digital.
                      </p>
                      <p>
                        Com a colaboração estreita da equipe Eclipsefy, mergulhamos nos valores, na visão e no estilo único da agência. O processo começou com uma imersão profunda na identidade da marca, resultando em um design de website que não apenas reflete a sofisticação da Eclipsefy, mas também proporciona uma experiência intuitiva aos visitantes. Desde a escolha da paleta de cores até a disposição estratégica de cada elemento, cada decisão foi tomada com a missão de traduzir a essência vibrante da agência para o ambiente digital.
                      </p>
                      <p> 
                        A implementação envolveu a incorporação de tecnologias de ponta para garantir desempenho, segurança e adaptabilidade em todas as plataformas. A interface resultante é não apenas visualmente impactante, mas também funcional, proporcionando uma navegação fluida e envolvente.
                      </p>
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/eclipsefy_1.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/eclipsefy_1.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/eclipsefy_1.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/eclipsefy_logo.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/eclipsefy_logo.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/eclipsefy_website.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="https://eclipsefy.com.br"
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/tattoo_p.jpg"></div>
                </div>
                <div class="details">
                  <h3>PH Tattoo</h3>
                  <span>Detalhes</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/phtattoo_bg.jpg"
                        style="background-image: url('img/portfolio/phtattoo_bg.jpg')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>PH Tattoo</h3>
                      <span>Social Media & Website</span>
                    </div>
                    <div class="text">
                      <p></p>
                      <p>
                        Embarcamos em uma jornada visual única ao colaborar com o Estúdio Paulinho Tattoo na criação de posts para suas mídias sociais. Uma oportunidade de traduzir a arte da tatuagem em uma linguagem digital que cativasse e inspirasse.
                      </p>
                      <p>
                        Imersos no mundo da tatuagem, exploramos estilos, cores e texturas para capturar a essência única do Estúdio Paulinho Tattoo. Cada post foi cuidadosamente concebido, equilibrando a elegância da arte com a vibração contemporânea das redes sociais. 
                      </p>
                      <p>
                        Colaboramos intensamente com a equipe do estúdio, garantindo que cada design refletisse sua paixão pela tatuagem e se conectasse com a audiência digital.
                      </p>
                      
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/tattoo_1.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/tattoo_1.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/phtattoo_logo.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/phtattoo_logo.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/phtattoo_website.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="https://paulinhotatuaria.com.br"
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/ativa_p.jpg"></div>
                </div>
                <div class="details">
                  <h3>Ativa Fit</h3>
                  <span>Detalhes</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/ativafit_bg.jpg"
                        style="background-image: url('img/portfolio/ativafit_bg.jpg')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Ativa fit</h3>
                      <span>Logo</span>
                    </div>
                    <div class="text">
                      <p></p>
                      <p>
                        Em um mergulho criativo, trilhamos o caminho da expressão visual para a Academia Ativa Fit. Um projeto que transcendeu a simples criação de uma logo, tornando-se uma imersão no mundo dinâmico e motivador desse espaço dedicado ao bem-estar e superação
                      </p>
                      <p>
                        Exploramos a essência vibrante da academia, buscando inspiração no movimento constante e na busca pela excelência física. A paleta de cores foi escolhida para transmitir vitalidade, enquanto as formas modernas refletiam a progressão constante. 
                      </p>  
                      <p> 
                        A colaboração estreita com a equipe da Ativa Fit foi fundamental, garantindo que cada detalhe da logo ressoasse com a visão única da academia.
                      </p>   
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/ativafit_1.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/ativafit_1.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/ativafit_logo.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/ativafit_logo.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/ativafit_website.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/ativafit_website.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/dragabrielly_p.jpg"></div>
                </div>
                <div class="details">
                  <h3>Dra. Gabrielly</h3>
                  <span>Detalhes</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/dragabrielly_bg.jpg"
                        style="background-image: url('img/portfolio/dragabrielly_bg.jpg')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Dra. Gabrielly</h3>
                      <span>Logo & Social Media</span>
                    </div>
                    <div class="text">
                      <p></p>
                      <p>
                        Em um sorriso, contamos uma história. Colaborar com a Dra. Gabrielly Odontologia na criação de posts para suas mídias sociais foi uma jornada para compartilhar a beleza e o impacto transformador de um sorriso saudável.
                      </p>
                      <p>
                        Entramos no universo da odontologia estética, explorando paletas de cores vivas e designs modernos. Cada post foi planejado com precisão, destacando procedimentos, dicas de cuidados e, acima de tudo, transmitindo confiança. 
                      </p>
                      <p>
                        A colaboração próxima com a Dra. Gabrielly permitiu alinhar nossa visão criativa com a autenticidade e profissionalismo de sua prática.
                      </p>
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/dragabrielly_1.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/dragabrielly_1.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/dragabrielly_logo.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/dragabrielly_logo.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/dragabrielly_website.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/dragabrielly_website.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>
            
            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="current">0</span>
                <span class="pagination_progress"
                  ><span class="all"
                    ><span
                      style="
                        transform: translate3d(0px, 0px, 0px) scaleX(0.75)
                          scaleY(1);
                      "
                    ></span></span
                ></span>
                <span class="total">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules

// Import Swiper Vue.js components
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `PortfolioComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(current, total);
        },
      },
    };
  },
};
</script>
